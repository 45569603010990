<template>
  <v-card class="pa-3" flat>
    <v-row align="center">
      <v-col cols="4">
        <div>
          {{ sessionDetail?.user?.name || "New User" }}
        </div>
        <div>
          {{ sessionDetail?.user?.email || "New email" }}
        </div>
      </v-col>
      <v-col cols="3">
        <div>Status</div>
        <div>In progress</div>
      </v-col>
      <v-col cols="3">
        <div>Start</div>
        <div>2 hours ago</div>
      </v-col>
      <v-col cols="2"> Options</v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-tabs v-model="tab">
          <v-tab> Overview</v-tab>
          <v-tab> Timeline</v-tab>
          <v-tab> Activites</v-tab>
          <v-tab> Feedback</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card flat>
              <v-row v-if="!isEmpty(sessionDetail?.stats)">
                <v-col cols="12">
                  <div class="subListFont bold-text">Stats</div>
                </v-col>
                <v-col
                  v-for="(stats, index) in Object.keys(sessionDetail.stats)"
                  :key="index"
                  cols="3"
                >
                  <span class="text-capitalize">{{ stats }}</span>
                  {{ sessionDetail.stats[stats] }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="subListFont bold-text" cols="12">
                  Recent Activities
                </v-col>
                <v-col cols="8">
                  <session-activity></session-activity>
                </v-col>
                <v-col cols="4">
                  <v-card class="pa-3" flat outlined>
                    <v-row>
                      <v-col cols="12">
                        <div class="mb-3 semi-bold">Location</div>
                        <div>
                          IP:
                          <span>
                            {{ sessionDetail?.context?.ip }}
                          </span>
                        </div>
                        <div>
                          Country:
                          <span>
                            {{ sessionDetail?.location?.country }}
                          </span>
                        </div>
                        <div>
                          Country Code:
                          <span>
                            {{ sessionDetail?.location?.country_code }}
                          </span>
                        </div>
                        <div>
                          City:
                          <span>
                            {{ sessionDetail?.location?.city }}
                          </span>
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <div class="mb-3 semi-bold">Identifiers</div>
                        <div>
                          Id:
                          <span>
                            {{ sessionDetail?.user?.id }}
                          </span>
                        </div>
                        <div>
                          Identity:
                          <span>
                            {{ sessionDetail?.user?.identity }}
                          </span>
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <div class="mb-3 semi-bold">Devices</div>
                        <div>
                          OS:
                          <span>
                            {{ sessionDetail?.context?.device?.os?.name }}
                          </span>
                        </div>
                        <div>
                          Model:
                          <span>
                            {{ sessionDetail?.context?.device?.model }}
                          </span>
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <div class="mb-3 semi-bold">App</div>
                        <div>
                          Name:
                          <span>
                            {{ sessionDetail?.context?.app?.name }}
                          </span>
                        </div>
                        <div>
                          Package:
                          <span>
                            {{ sessionDetail?.context?.app?.package }}
                          </span>
                        </div>
                        <div>
                          Version:
                          <span>
                            {{ sessionDetail?.context?.app?.version }}
                          </span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
          <v-tab-item> time</v-tab-item>
          <v-tab-item>
            <session-activity></session-activity>
          </v-tab-item>
          <v-tab-item>
            <feedback-tab></feedback-tab>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {
  GET_SESSION_PERFORMANCE,
  GET_USER_SESSION,
  GET_USER_SESSION_ACTIVITIES,
} from "@/store/apps/sessions.module";
import dayjs from "dayjs";
import SessionActivity from "@/view/components/Sessions/SessionActivity";
import FeedbackTab from "@/view/components/Sessions/SessionDetailPage/SessionFeedback/index.vue";
import { isEmpty } from "@/core/services/helper.service.js";

export default {
  components: { SessionActivity, FeedbackTab },
  data() {
    return {
      sessionDetail: {},
      dayjs,
      tab: null,
      isEmpty,
      sessionPerformance: {},
      data: {
        id: "M3gEdGoWN4",
        context: {
          ip: "127.0.0.1",
          app: {
            name: "TestApp.io35",
            package: "testapp.io",
            version: "1.0.34",
            version_code: "43",
          },
          sdk: {
            name: "Swift",
            version: "1.0.0",
            version_code: "1",
          },
          device: {
            id: "",
            os: {
              name: "android",
              version: "10",
              sdk_version: "30",
            },
            type: "phone",
            udid: "1234567892",
            uuid: "924C35F0-BFAC-4851-87AC-7FA8F580039B",
            model: "Pixel 4",
            locale: "en_US",
            screen: {
              width: 1080,
              height: 1920,
              density: 2.726,
            },
            timezone: "Asia/Kolkata",
            push_token: "",
            manufacturer: "Google",
            push_enabled: false,
            installer_store: "playstore",
          },
          user_agent: "PostmanRuntime/7.30.0",
        },
        anonymous_id: "924C35F0-BFAC-4851-87AC-7FA8F580013A",
        user: {
          id: "OZ5Ep7OoP0",
          name: null,
          email: null,
          identity: "924C35F0-BFAC-4851-87AC-7FA8F580013A",
        },
        location: {
          city: "Dubai",
          country: "United Arab Emirates",
          country_code: "ARE",
        },
        user_agent: "PostmanRuntime/7.30.0",
        stats: {},
        start_at: "2023-04-10T19:59:13.49+04:00",
        end_at: null,
        status: 1,
      },
      loading: false,
      sessionActivities: [],
    };
  },
  created() {
    this.getAllSessionData();
  },
  methods: {
    getAllUserSession() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(GET_USER_SESSION, {
          user_id: this.$route.params.user_id,
          timestamp: "",
        })
        .then((response) => {
          this.sessionDetail = response?.data;
          this.loading = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.loading = false;
        });
    },
    getAllUserSessionActivities() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(GET_USER_SESSION_ACTIVITIES, {
          user_id: this.$route.params.user_id,
          timestamp: "",
        })
        .then((response) => {
          this.sessionDetail = response?.data;
          this.loading = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.loading = false;
        });
    },
    getSessionPerformance() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(GET_SESSION_PERFORMANCE, {
          user_id: this.$route.params.user_id,
          timestamp: "",
        })
        .then((response) => {
          this.sessionPerformance = response?.results;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message);
        });
    },
  },
};
</script>
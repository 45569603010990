var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.busy || this.currentPageData.length ? _c('v-row', [_c('v-col', {
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "cols": "4"
    }
  }, [!_vm.busy ? [_c('stack', {
    staticClass: "feedback-cards"
  }, _vm._l(_vm.currentPageData, function (feedback) {
    var _vm$activeFeedback;
    return _c('v-card', {
      key: feedback.id,
      staticClass: "feedback-card",
      class: ((_vm$activeFeedback = _vm.activeFeedback) === null || _vm$activeFeedback === void 0 ? void 0 : _vm$activeFeedback.id) === feedback.id ? 'feedback-card--active' : '',
      attrs: {
        "outlined": "",
        "rounded": ""
      },
      on: {
        "click": function click($event) {
          _vm.activeFeedback = feedback;
        }
      }
    }, [_c('div', {
      staticClass: "feedback-card__title"
    }, [_vm._v("Feedback " + _vm._s(feedback.id))]), _c('div', {
      staticClass: "feedback-card__message"
    }, [feedback.message ? [_vm._v(" " + _vm._s(feedback.message) + " ")] : _c('span', {
      staticClass: "feedback-card__title--empty"
    }, [_vm._v("No message")])], 2), _c('div', {
      staticClass: "feedback-card__bottom"
    }, [_c('div', {
      staticClass: "feedback-card__created-at"
    }, [_c('date-tooltip', {
      attrs: {
        "date": feedback.created_at
      }
    })], 1), _c('div', {
      staticClass: "feedback-card__icons"
    }, [feedback.stats.attachments ? _c('flex-row', {
      attrs: {
        "gap": "4px"
      }
    }, [_c('span', [_vm._v(_vm._s(feedback.stats.attachments))]), _c('v-icon', [_vm._v(" mdi-attachment")])], 1) : _vm._e(), _c('feedback-status', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: feedback.status,
        expression: "feedback.status"
      }],
      attrs: {
        "status": feedback.status
      }
    })], 1)])]);
  }), 1), this.isPaginationVisible ? _c('div', {
    staticClass: "feedback-cards__pagination"
  }, [_c('v-btn', {
    attrs: {
      "disabled": !_vm.showPrev,
      "icon": ""
    },
    on: {
      "click": _vm.prevPage
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-left")])], 1), _c('v-btn', {
    attrs: {
      "disabled": !_vm.showNext,
      "icon": ""
    },
    on: {
      "click": _vm.nextPage
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-right")])], 1)], 1) : _vm._e(), _vm.feedbackId && _vm.getSessionDetails.stats.feedback > 1 ? _c('v-row', {
    staticStyle: {
      "margin-top": "16px"
    },
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.handleViewAll
    }
  }, [_vm._v("View all")])], 1)], 1) : _vm._e()] : _c('stack', _vm._l(4, function (index) {
    return _c('v-skeleton-loader', {
      key: index,
      attrs: {
        "type": "article"
      }
    });
  }), 1)], 2), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('active-feedback', {
    attrs: {
      "active-feedback": _vm.activeFeedback
    },
    on: {
      "onAttachmentDelete": _vm.handleAttachmentDelete,
      "onDelete": _vm.handleFeedbackDelete,
      "onStatusChange": _vm.handleFeedbackStatusChange
    }
  })], 1)], 1) : _c('no-data', {
    attrs: {
      "first-text": "No data",
      "second-text": "No feedback has been collected yet. Kickstart your feedback collection by sharing your public install page and encouraging usage of our app."
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }
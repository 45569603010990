var render = function render(){
  var _vm$sessionDetail, _vm$sessionDetail$use, _vm$sessionDetail2, _vm$sessionDetail2$us, _vm$sessionDetail3, _vm$sessionDetail4, _vm$sessionDetail4$co, _vm$sessionDetail5, _vm$sessionDetail5$lo, _vm$sessionDetail6, _vm$sessionDetail6$lo, _vm$sessionDetail7, _vm$sessionDetail7$lo, _vm$sessionDetail8, _vm$sessionDetail8$us, _vm$sessionDetail9, _vm$sessionDetail9$us, _vm$sessionDetail10, _vm$sessionDetail10$c, _vm$sessionDetail10$c2, _vm$sessionDetail10$c3, _vm$sessionDetail11, _vm$sessionDetail11$c, _vm$sessionDetail11$c2, _vm$sessionDetail12, _vm$sessionDetail12$c, _vm$sessionDetail12$c2, _vm$sessionDetail13, _vm$sessionDetail13$c, _vm$sessionDetail13$c2, _vm$sessionDetail14, _vm$sessionDetail14$c, _vm$sessionDetail14$c2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "pa-3",
    attrs: {
      "flat": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', [_vm._v(" " + _vm._s(((_vm$sessionDetail = _vm.sessionDetail) === null || _vm$sessionDetail === void 0 ? void 0 : (_vm$sessionDetail$use = _vm$sessionDetail.user) === null || _vm$sessionDetail$use === void 0 ? void 0 : _vm$sessionDetail$use.name) || "New User") + " ")]), _c('div', [_vm._v(" " + _vm._s(((_vm$sessionDetail2 = _vm.sessionDetail) === null || _vm$sessionDetail2 === void 0 ? void 0 : (_vm$sessionDetail2$us = _vm$sessionDetail2.user) === null || _vm$sessionDetail2$us === void 0 ? void 0 : _vm$sessionDetail2$us.email) || "New email") + " ")])]), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('div', [_vm._v("Status")]), _c('div', [_vm._v("In progress")])]), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('div', [_vm._v("Start")]), _c('div', [_vm._v("2 hours ago")])]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" Options")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', [_vm._v(" Overview")]), _c('v-tab', [_vm._v(" Timeline")]), _c('v-tab', [_vm._v(" Activites")]), _c('v-tab', [_vm._v(" Feedback")])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [!_vm.isEmpty((_vm$sessionDetail3 = _vm.sessionDetail) === null || _vm$sessionDetail3 === void 0 ? void 0 : _vm$sessionDetail3.stats) ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "subListFont bold-text"
  }, [_vm._v("Stats")])]), _vm._l(Object.keys(_vm.sessionDetail.stats), function (stats, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "3"
      }
    }, [_c('span', {
      staticClass: "text-capitalize"
    }, [_vm._v(_vm._s(stats))]), _vm._v(" " + _vm._s(_vm.sessionDetail.stats[stats]) + " ")]);
  })], 2) : _vm._e(), _c('v-row', [_c('v-col', {
    staticClass: "subListFont bold-text",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Recent Activities ")]), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('session-activity')], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-card', {
    staticClass: "pa-3",
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "mb-3 semi-bold"
  }, [_vm._v("Location")]), _c('div', [_vm._v(" IP: "), _c('span', [_vm._v(" " + _vm._s((_vm$sessionDetail4 = _vm.sessionDetail) === null || _vm$sessionDetail4 === void 0 ? void 0 : (_vm$sessionDetail4$co = _vm$sessionDetail4.context) === null || _vm$sessionDetail4$co === void 0 ? void 0 : _vm$sessionDetail4$co.ip) + " ")])]), _c('div', [_vm._v(" Country: "), _c('span', [_vm._v(" " + _vm._s((_vm$sessionDetail5 = _vm.sessionDetail) === null || _vm$sessionDetail5 === void 0 ? void 0 : (_vm$sessionDetail5$lo = _vm$sessionDetail5.location) === null || _vm$sessionDetail5$lo === void 0 ? void 0 : _vm$sessionDetail5$lo.country) + " ")])]), _c('div', [_vm._v(" Country Code: "), _c('span', [_vm._v(" " + _vm._s((_vm$sessionDetail6 = _vm.sessionDetail) === null || _vm$sessionDetail6 === void 0 ? void 0 : (_vm$sessionDetail6$lo = _vm$sessionDetail6.location) === null || _vm$sessionDetail6$lo === void 0 ? void 0 : _vm$sessionDetail6$lo.country_code) + " ")])]), _c('div', [_vm._v(" City: "), _c('span', [_vm._v(" " + _vm._s((_vm$sessionDetail7 = _vm.sessionDetail) === null || _vm$sessionDetail7 === void 0 ? void 0 : (_vm$sessionDetail7$lo = _vm$sessionDetail7.location) === null || _vm$sessionDetail7$lo === void 0 ? void 0 : _vm$sessionDetail7$lo.city) + " ")])])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "mb-3 semi-bold"
  }, [_vm._v("Identifiers")]), _c('div', [_vm._v(" Id: "), _c('span', [_vm._v(" " + _vm._s((_vm$sessionDetail8 = _vm.sessionDetail) === null || _vm$sessionDetail8 === void 0 ? void 0 : (_vm$sessionDetail8$us = _vm$sessionDetail8.user) === null || _vm$sessionDetail8$us === void 0 ? void 0 : _vm$sessionDetail8$us.id) + " ")])]), _c('div', [_vm._v(" Identity: "), _c('span', [_vm._v(" " + _vm._s((_vm$sessionDetail9 = _vm.sessionDetail) === null || _vm$sessionDetail9 === void 0 ? void 0 : (_vm$sessionDetail9$us = _vm$sessionDetail9.user) === null || _vm$sessionDetail9$us === void 0 ? void 0 : _vm$sessionDetail9$us.identity) + " ")])])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "mb-3 semi-bold"
  }, [_vm._v("Devices")]), _c('div', [_vm._v(" OS: "), _c('span', [_vm._v(" " + _vm._s((_vm$sessionDetail10 = _vm.sessionDetail) === null || _vm$sessionDetail10 === void 0 ? void 0 : (_vm$sessionDetail10$c = _vm$sessionDetail10.context) === null || _vm$sessionDetail10$c === void 0 ? void 0 : (_vm$sessionDetail10$c2 = _vm$sessionDetail10$c.device) === null || _vm$sessionDetail10$c2 === void 0 ? void 0 : (_vm$sessionDetail10$c3 = _vm$sessionDetail10$c2.os) === null || _vm$sessionDetail10$c3 === void 0 ? void 0 : _vm$sessionDetail10$c3.name) + " ")])]), _c('div', [_vm._v(" Model: "), _c('span', [_vm._v(" " + _vm._s((_vm$sessionDetail11 = _vm.sessionDetail) === null || _vm$sessionDetail11 === void 0 ? void 0 : (_vm$sessionDetail11$c = _vm$sessionDetail11.context) === null || _vm$sessionDetail11$c === void 0 ? void 0 : (_vm$sessionDetail11$c2 = _vm$sessionDetail11$c.device) === null || _vm$sessionDetail11$c2 === void 0 ? void 0 : _vm$sessionDetail11$c2.model) + " ")])])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "mb-3 semi-bold"
  }, [_vm._v("App")]), _c('div', [_vm._v(" Name: "), _c('span', [_vm._v(" " + _vm._s((_vm$sessionDetail12 = _vm.sessionDetail) === null || _vm$sessionDetail12 === void 0 ? void 0 : (_vm$sessionDetail12$c = _vm$sessionDetail12.context) === null || _vm$sessionDetail12$c === void 0 ? void 0 : (_vm$sessionDetail12$c2 = _vm$sessionDetail12$c.app) === null || _vm$sessionDetail12$c2 === void 0 ? void 0 : _vm$sessionDetail12$c2.name) + " ")])]), _c('div', [_vm._v(" Package: "), _c('span', [_vm._v(" " + _vm._s((_vm$sessionDetail13 = _vm.sessionDetail) === null || _vm$sessionDetail13 === void 0 ? void 0 : (_vm$sessionDetail13$c = _vm$sessionDetail13.context) === null || _vm$sessionDetail13$c === void 0 ? void 0 : (_vm$sessionDetail13$c2 = _vm$sessionDetail13$c.app) === null || _vm$sessionDetail13$c2 === void 0 ? void 0 : _vm$sessionDetail13$c2.package) + " ")])]), _c('div', [_vm._v(" Version: "), _c('span', [_vm._v(" " + _vm._s((_vm$sessionDetail14 = _vm.sessionDetail) === null || _vm$sessionDetail14 === void 0 ? void 0 : (_vm$sessionDetail14$c = _vm$sessionDetail14.context) === null || _vm$sessionDetail14$c === void 0 ? void 0 : (_vm$sessionDetail14$c2 = _vm$sessionDetail14$c.app) === null || _vm$sessionDetail14$c2 === void 0 ? void 0 : _vm$sessionDetail14$c2.version) + " ")])])])], 1)], 1)], 1)], 1)], 1)], 1), _c('v-tab-item', [_vm._v(" time")]), _c('v-tab-item', [_c('session-activity')], 1), _c('v-tab-item', [_c('feedback-tab')], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
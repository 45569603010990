<template>
  <v-row v-if="busy || this.currentPageData.length">
    <v-col cols="4" style="position: relative">
      <template v-if="!busy">
        <stack class="feedback-cards">
          <v-card
            v-for="feedback in currentPageData"
            :key="feedback.id"
            :class="
              activeFeedback?.id === feedback.id ? 'feedback-card--active' : ''
            "
            class="feedback-card"
            outlined
            rounded
            @click="activeFeedback = feedback"
          >
            <div class="feedback-card__title">Feedback {{ feedback.id }}</div>
            <div class="feedback-card__message">
              <template v-if="feedback.message">
                {{ feedback.message }}
              </template>
              <span v-else class="feedback-card__title--empty">No message</span>
            </div>
            <div class="feedback-card__bottom">
              <div class="feedback-card__created-at">
                <date-tooltip :date="feedback.created_at" />
              </div>
              <div class="feedback-card__icons">
                <flex-row v-if="feedback.stats.attachments" gap="4px">
                  <span>{{ feedback.stats.attachments }}</span>
                  <v-icon> mdi-attachment</v-icon>
                </flex-row>
                <feedback-status
                  v-show="feedback.status"
                  :status="feedback.status"
                ></feedback-status>
              </div>
            </div>
          </v-card>
        </stack>
        <div v-if="this.isPaginationVisible" class="feedback-cards__pagination">
          <v-btn :disabled="!showPrev" icon @click="prevPage">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn :disabled="!showNext" icon @click="nextPage">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
        <v-row
          v-if="feedbackId && getSessionDetails.stats.feedback > 1"
          justify="center"
          style="margin-top: 16px"
        >
          <v-col cols="auto">
            <v-btn color="primary" @click="handleViewAll">View all</v-btn>
          </v-col>
        </v-row>
      </template>
      <stack v-else>
        <v-skeleton-loader
          v-for="index in 4"
          :key="index"
          type="article"
        ></v-skeleton-loader>
      </stack>
    </v-col>
    <v-col cols="8">
      <active-feedback
        :active-feedback="activeFeedback"
        @onAttachmentDelete="handleAttachmentDelete"
        @onDelete="handleFeedbackDelete"
        @onStatusChange="handleFeedbackStatusChange"
      />
    </v-col>
  </v-row>

  <no-data
    v-else
    first-text="No data"
    second-text="No feedback has been collected yet. Kickstart your feedback collection by sharing your public install page and encouraging usage of our app."
  ></no-data>
</template>

<script>
import { mapGetters } from "vuex";
import Stack from "@/view/components/Common/Stack.vue";
import FeedbackStatus from "@/view/components/Feedback/FeedbackStatus.vue";
import { GET_SESSIONS_FEEDBACK } from "@/store/apps/sessions.module";
import NoData from "@/view/components/Common/NoData.vue";
import apiRoutes from "@/consts/routes/apiRoutes";
import ActiveFeedback from "@/view/components/Sessions/SessionDetailPage/SessionFeedback/ActiveFeedback.vue";

export default {
  components: {
    ActiveFeedback,
    Stack,
    FeedbackStatus,
    NoData,
  },
  data() {
    return {
      deleteFeedbackDialog: false,
      activeFeedbackStatus: null,
      busy: false,
      attachmentsBusy: true,
      activeFeedback: null,
      attachments: null,
      activeMedia: null,
      feedbackPagination: {
        currentPage: 0,
        itemsPerPage: 5,
        totalItems: 0,
        totalPages: 0,
      },
      currentPageData: [],
      allLoadedData: [],
    };
  },
  computed: {
    ...mapGetters({
      getAllSessionFeedback: "getAllSessionFeedback",
      getTotalSessionFeedback: "getTotalSessionFeedback",
      getSessionFeedbackCount: "getSessionFeedbackCount",
      getSessionDetails: "getSessionDetails",
    }),
    showPrev() {
      return (
        this.isPaginationVisible && this.feedbackPagination.currentPage + 1 > 1
      );
    },
    showNext() {
      return (
        this.isPaginationVisible &&
        this.feedbackPagination.currentPage + 1 <
          this.feedbackPagination.totalPages
      );
    },
    isPaginationVisible() {
      return (
        this.feedbackPagination.totalItems >
        this.feedbackPagination.itemsPerPage
      );
    },
    lastFeedbackItem() {
      return this.currentPageData[this.currentPageData.length - 1];
    },
    feedbackId() {
      return this.$route.params.feedback_id;
    },
  },
  watch: {
    getSessionFeedbackCount: function (value) {
      this.feedbackPagination.totalItems = value;
      this.feedbackPagination.totalPages = Math.ceil(
        this.feedbackPagination.totalItems /
          this.feedbackPagination.itemsPerPage
      );
    },
    getAllSessionFeedback: function (value) {
      if (value.length) {
        this.currentPageData = value;
      }
    },
    getTotalSessionFeedback: function (value) {
      this.allLoadedData = value;
    },
    "feedbackPagination.currentPage": function (val, oldVal) {
      if (val == null || val < 0 || val === oldVal) {
        return;
      }

      const { itemsPerPage } = this.feedbackPagination;
      let offset = val * itemsPerPage;

      if (val > oldVal) {
        if (this.allLoadedData.length > (oldVal + 1) * itemsPerPage) {
          this.currentPageData = this.allLoadedData.slice(
            offset,
            offset + itemsPerPage
          );
        } else {
          const lastId = this.lastFeedbackItem?.id;
          if (lastId) {
            this.getSessionFeedback(lastId);
          }
        }
      } else {
        this.currentPageData = this.allLoadedData.slice(
          offset,
          offset + itemsPerPage
        );
      }
    },
    "$route.params.feedback_id": function (value) {
      if (value) {
        this.getFeedbackById(value);
      }
    },
  },
  created() {
    if (this.feedbackId) {
      this.getFeedbackById(this.feedbackId);
    } else {
      this.getSessionFeedback().then(({ data }) => {
        if (data.length) {
          const [activeFeedback] = data;
          this.activeFeedback = activeFeedback;
        }
      });
    }
  },
  methods: {
    getSessionFeedback(lastID = "") {
      if (this.busy) return;
      this.busy = true;
      return this.$store
        .dispatch(GET_SESSIONS_FEEDBACK, {
          app_id: this.$route.params.app_id || "",
          session_id: this.$route.params.session_id || "",
          limit: this.feedbackPagination.itemsPerPage,
          page: this.feedbackPagination.currentPage + 1,
          last_id: lastID,
        })
        .then((data) => {
          return data;
        })
        .catch((error) => {
          this.notifyErrorMessage(error.message);
        })
        .finally(() => {
          this.busy = false;
        });
    },
    getFeedbackById(feedbackId) {
      this.busy = true;

      return this.$coreApi
        .get(apiRoutes.core.session.feedbackById(feedbackId))
        .then(({ data }) => {
          this.currentPageData = [data];
          this.activeFeedback = data;
        })
        .catch((error) => {
          this.notifyErrorMessage(error.message);
        })
        .finally(() => {
          this.busy = false;
        });
    },
    nextPage() {
      if (
        this.feedbackPagination.currentPage < this.feedbackPagination.totalPages
      ) {
        this.feedbackPagination.currentPage += 1;
      }
    },
    prevPage() {
      if (this.feedbackPagination.currentPage + 1 > 1) {
        this.feedbackPagination.currentPage -= 1;
      }
    },
    handleViewAll() {
      const { app_id, session_id } = this.$route.params;
      this.$router.push({
        name: "session-details-feedback",
        params: {
          app_id,
          session_id,
        },
      });
      this.getSessionFeedback();
    },
    handleFeedbackStatusChange(status) {
      const mapFeedback = (feedback) => {
        if (feedback.id === this.activeFeedback.id) {
          return {
            ...feedback,
            status,
          };
        }
        return feedback;
      };
      this.allLoadedData = this.allLoadedData.map(mapFeedback);
      this.currentPageData = this.currentPageData.map(mapFeedback);
      this.activeFeedback = { ...this.activeFeedback, status };
    },
    async handleFeedbackDelete(feedbackId) {
      const feedbackIndex = this.allLoadedData.findIndex(
        (feedback) => feedback.id === feedbackId
      );

      const isLast = feedbackIndex === this.allLoadedData.length - 1;

      let nextActiveItem;
      if (isLast) {
        nextActiveItem = this.allLoadedData[feedbackIndex - 1];
      } else {
        nextActiveItem = this.allLoadedData[feedbackIndex + 1];
      }

      this.allLoadedData = this.allLoadedData.filter(
        (feedback) => feedback.id !== feedbackId
      );

      const allLoadedDataChunks = this.allLoadedData.reduce(
        (resultArray, item, index) => {
          const chunkIndex = Math.floor(
            index / this.feedbackPagination.itemsPerPage
          );

          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []; // start a new chunk
          }

          resultArray[chunkIndex].push(item);

          return resultArray;
        },
        []
      );

      this.feedbackPagination.totalItems -= 1;
      this.feedbackPagination.totalPages = allLoadedDataChunks.length;
      if (
        isLast &&
        this.feedbackPagination.currentPage > 0 &&
        this.currentPageData.length === 1
      ) {
        this.feedbackPagination.currentPage -= 1;
      }
      this.currentPageData =
        allLoadedDataChunks[this.feedbackPagination.currentPage] ?? [];
      this.activeFeedback = nextActiveItem;
    },
    handleAttachmentDelete(feedbackId) {
      const mapData = (feedback) => {
        if (feedback.id === feedbackId) {
          return {
            ...feedback,
            stats: {
              ...feedback.stats,
              attachments: feedback.stats.attachments - 1,
            },
          };
        }
        return feedback;
      };

      this.allLoadedData = this.allLoadedData.map(mapData);
      this.currentPageData = this.currentPageData.map(mapData);
    },
  },
};
</script>

<style lang="scss">
.v-toolbar {
  box-shadow: none;
}

.feedback-cards__pagination {
  display: flex;
  gap: 48px;
  justify-content: center;
  left: 50%;
  margin-top: 16px;
  position: absolute;
  transform: translateX(-50%);
}

.feedback-card {
  $card: &;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  min-height: 112px;
  padding: 12px 16px 8px 12px;
  position: relative;
  transition: border-color 0.2s ease-in-out;

  &__title {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.7;
  }

  &__message {
    font-size: 14px;
    line-height: 1.7;

    &--empty {
      color: $grey;
    }
  }

  &__bottom {
    align-items: center;
    color: $grey;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
  }

  &__icons {
    align-items: center;
    display: flex;
    gap: 16px;
  }

  &--active {
    background: linear-gradient(
        0deg,
        rgba(64, 98, 215, 0.08) 0%,
        rgba(64, 98, 215, 0.08) 100%
      ),
      #fff !important;
    border-color: $blue !important;

    &:before {
      background: $blue;
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 4px;
    }

    #{$card}__title,
    #{$card}__message {
      color: $blue;
    }
  }

  &__created-at {
    font-size: 12px;
  }
}
</style>

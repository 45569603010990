<template>
    <div class="mt-10">
        <user-session-detail></user-session-detail>
    </div>
</template>

<script>
import UserSessionDetail from "@/view/components/Sessions/UserSessionDetail";
export default {
    components: { UserSessionDetail },
};
</script>
